<template>
  <div>
    <el-alert v-if="errMsg" title="错误！" type="error" :description="errMsg" show-icon></el-alert>
    <div style="margin: 20px; font-size: 36px;">{{vs.主场}}VS{{vs.客场}}</div>
    <el-table v-if="realTime" :data="tableData" stripe style="margin: auto;" @row-click="click">
      <el-table-column label="公司" width="260" align="right">
        <!--template #default="scope">
          {{companies[scope.row.companyId].name}} --- {{scope.row.changeTime}}
        </template-->
        <template #default="scope">
          {{scope.row.companyName}}
        </template>
      </el-table-column>
      <el-table-column width="60" align="center">
        <template #default="scope">
          <span class="bd_btm">主胜</span>
          <span @data-index="scope.row.type">客胜</span>
        </template>
      </el-table-column>
      <el-table-column label="1:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh1a0?scope.row.wh1a0:'-'}}</span>
          <span>{{scope.row.lh0a1?scope.row.lh0a1:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh2a0?scope.row.wh2a0:'-'}}</span>
          <span>{{scope.row.lh0a2?scope.row.lh0a2:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh2a1?scope.row.wh2a1:'-'}}</span>
          <span>{{scope.row.lh1a2?scope.row.lh1a2:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a0?scope.row.wh3a0:'-'}}</span>
          <span>{{scope.row.lh0a3?scope.row.lh0a3:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a1?scope.row.wh3a1:'-'}}</span>
          <span>{{scope.row.lh1a3?scope.row.lh1a3:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:2" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a2?scope.row.wh3a2:'-'}}</span>
          <span>{{scope.row.lh2a3?scope.row.lh2a3:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a0?scope.row.wh4a0:'-'}}</span>
          <span>{{scope.row.lh0a4?scope.row.lh0a4:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a1?scope.row.wh4a1:'-'}}</span>
          <span>{{scope.row.lh1a4?scope.row.lh1a4:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:2" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a2?scope.row.wh4a2:'-'}}</span>
          <span>{{scope.row.lh2a4?scope.row.lh2a4:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:3" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a3?scope.row.wh4a3:'-'}}</span>
          <span>{{scope.row.lh3a4?scope.row.lh3a4:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="0:0" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh0a0?scope.row.dh0a0:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="1:1" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh1a1?scope.row.dh1a1:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:2" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh2a2?scope.row.dh2a2:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:3" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh3a3?scope.row.dh3a3:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:4" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh4a4?scope.row.dh4a4:'-'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-else :data="tableData" stripe style="margin: auto;" @row-click="click">
      <el-table-column label="公司" width="260" align="right">
        <!--template #default="scope">
          {{companies[scope.row.companyId].name}}  --- {{scope.row.changeTime}}
        </template-->
        <template #default="scope">
          {{scope.row.companyName}}
        </template>
      </el-table-column>
      <el-table-column width="60" align="center">
        <template #default="scope">
          <span class="bd_btm">主胜</span>
          <span @data-index="scope.row.type">客胜</span>
        </template>
      </el-table-column>
      <el-table-column label="1:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh1a0?scope.row.wh1a0/100:'-'}}</span>
          <span>{{scope.row.lh0a1?scope.row.lh0a1/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh2a0?scope.row.wh2a0/100:'-'}}</span>
          <span>{{scope.row.lh0a2?scope.row.lh0a2/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh2a1?scope.row.wh2a1/100:'-'}}</span>
          <span>{{scope.row.lh1a2?scope.row.lh1a2/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a0?scope.row.wh3a0/100:'-'}}</span>
          <span>{{scope.row.lh0a3?scope.row.lh0a3/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a1?scope.row.wh3a1/100:'-'}}</span>
          <span>{{scope.row.lh1a3?scope.row.lh1a3/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:2" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh3a2?scope.row.wh3a2/100:'-'}}</span>
          <span>{{scope.row.lh2a3?scope.row.lh2a3/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:0" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a0?scope.row.wh4a0/100:'-'}}</span>
          <span>{{scope.row.lh0a4?scope.row.lh0a4/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:1" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a1?scope.row.wh4a1/100:'-'}}</span>
          <span>{{scope.row.lh1a4?scope.row.lh1a4/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:2" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a2?scope.row.wh4a2/100:'-'}}</span>
          <span>{{scope.row.lh2a4?scope.row.lh2a4/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:3" width="80" align="center">
        <template #default="scope">
          <span class="bd_btm">{{scope.row.wh4a3?scope.row.wh4a3/100:'-'}}</span>
          <span>{{scope.row.lh3a4?scope.row.lh3a4/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="0:0" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh0a0?scope.row.dh0a0/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="1:1" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh1a1?scope.row.dh1a1/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="2:2" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh2a2?scope.row.dh2a2/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="3:3" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh3a3?scope.row.dh3a3/100:'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="4:4" width="80" align="center">
        <template #default="scope">
          <span>{{scope.row.dh4a4?scope.row.dh4a4/100:'-'}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      errMsg: '',
      realTime: '',
      companies: {
        1: { name: '威廉希尔', country: '英国', sort: 1 },
        2: { name: '立博', country: '英国', sort: 1000 },
        3: { name: '澳门', country: '中国澳门', sort: 2 },
        4: { name: '皇冠', country: '英属维京群岛', sort: 1000 },
        5: { name: '伟德', country: '直布罗陀', sort: 3 },
        6: { name: 'Bwin', country: '奥地利', sort: 4 },
        7: { name: 'Bet365', country: '马耳他', sort: 5 },
        8: { name: '金宝博', country: '马恩岛', sort: 6 },
        9: { name: '易胜博', country: '安提瓜和巴布达', sort: 1000 },
        10: { name: 'SNAI', country: '意大利', sort: 1000 },
        11: { name: '10BET', country: '英国', sort: 1000 },
        12: { name: '必发', country: null, sort: 1000 },
        13: { name: '盈禾', country: '菲律宾', sort: 1000 },
        14: { name: '1xBet', country: null, sort: 1000 },
        15: { name: '明升', country: '菲律宾', sort: 1000 },
        16: { name: 'Interwetten', country: '塞浦路斯', sort: 7 },
        17: { name: '平博', country: '荷兰', sort: 1000 },
        18: { name: '利记', country: '英国', sort: 8 },
        19: { name: '香港马会', country: '中国香港', sort: 1000 },
        20: { name: '优胜客', country: '马耳他', sort: 9 },
        21: { name: '沙巴', country: '哥斯达黎加', sort: 1000 },
        22: { name: '壹貳博', country: '菲律宾', sort: 1000 },
        23: { name: '马博', country: '荷属安的列斯群岛', sort: 1000 },
        24: { name: 'Oddset', country: '德国', sort: 1000 },
        25: { name: 'Coral', country: '英国', sort: 1000 },
        26: { name: 'Gamebookers', country: '安提瓜和巴布达', sort: 1000 },
        27: { name: '博天堂', country: '瓦努阿图', sort: 1000 },
        28: { name: 'Eurobet', country: '英国', sort: 1000 },
        29: { name: '必威', country: '直布罗陀', sort: 1000 },
        31: { name: '大发', country: '菲律宾', sort: 1000 },
        34: { name: 'Leon', country: '伯利兹', sort: 1000 },
        35: { name: 'Betshop', country: '英国', sort: 1000 },
        36: { name: '平均欧指', country: null, sort: 1000 },
        39: { name: 'Bet-at-home', country: null, sort: 1000 },
        51: { name: 'ComeOn', country: null, sort: 1000 },
        55: { name: '博发', country: null, sort: 1000 },
        57: { name: 'Betsafe', country: null, sort: 1000 },
        59: { name: '888sport', country: null, sort: 1000 },
        62: { name: 'BoyleSports', country: null, sort: 1000 },
        63: { name: 'Intertops', country: null, sort: 1000 },
        64: { name: 'BetFinal', country: null, sort: 1000 },
        65: { name: 'NordicBet', country: null, sort: 1000 },
        67: { name: 'BetInAsia', country: null, sort: 1000 },
        72: { name: 'Betsson', country: null, sort: 1000 },
        73: { name: 'Betfair Exchange', country: null, sort: 1000 },
        74: { name: 'Chance.cz', country: null, sort: 1000 },
        75: { name: 'iFortuna.cz', country: null, sort: 1000 },
        77: { name: 'Tipsport.cz', country: null, sort: 1000 },
        78: { name: 'bwin.it', country: null, sort: 1000 },
        83: { name: 'STS.pl', country: null, sort: 1000 },
        96: { name: 'Matchbook', country: null, sort: 1000 },
        136: { name: 'youwin', country: null, sort: 1000 },
        137: { name: 'bwin.fr', country: null, sort: 1000 },
        146: { name: 'France Pari', country: null, sort: 1000 },
        148: { name: 'Betclic.fr', country: null, sort: 1000 },
        155: { name: 'Interwetten.es', country: null, sort: 1000 },
        163: { name: 'Unibet.it', country: null, sort: 1000 },
        169: { name: 'eFortuna.pl', country: null, sort: 1000 },
        170: { name: 'iFortuna.sk', country: null, sort: 1000 },
        188: { name: 'WilliamHill.it', country: null, sort: 1000 },
        208: { name: 'bwin.es', country: null, sort: 1000 },
        222: { name: 'Sportium.es', country: null, sort: 1000 },
        227: { name: 'Tipsport.sk', country: null, sort: 1000 },
        230: { name: 'SAZKAbet.cz', country: null, sort: 1000 },
        235: { name: 'bet365.it', country: null, sort: 1000 },
        268: { name: '1xStavka.ru', country: null, sort: 1000 },
        269: { name: 'Winline.ru', country: null, sort: 1000 },
        270: { name: 'Leon.ru', country: null, sort: 1000 },
        282: { name: 'PlanetWin', country: null, sort: 1000 },
        283: { name: 'Coolbet', country: null, sort: 1000 },
        287: { name: 'Totolotek.pl', country: null, sort: 1000 },
        291: { name: 'Asianodds', country: null, sort: 1000 },
        365: { name: 'GGBET', country: null, sort: 1000 },
        373: { name: 'GGBET.ru', country: null, sort: 1000 },
        392: { name: 'Betsensation', country: null, sort: 1000 },
        395: { name: '10x10bet', country: null, sort: 1000 },
        397: { name: 'Curebet', country: null, sort: 1000 },
        398: { name: 'Lasbet', country: null, sort: 1000 },
        402: { name: 'FEZbet', country: null, sort: 1000 },
        424: { name: 'Ditobet', country: null, sort: 1000 },
        9999: { name: '未标明', country: null, sort: 1000 }
      },
      vs: {},
      tableData: []
    }
  },
  mounted () {
    console.log(this.$route.query)
    this.vs = this.$route.query
    this.$axios.get('api/okoooodd', {
      params: this.$route.query
    }).then(res => {
      console.log(res.data)
      const temp = res.data
      // const tableData = [null, null, null, null, null, null, null, null, null]
      if (temp.errcode === -1) {
        this.$router.push({ path: '/login' })
      } else if (temp.errcode > 0) {
        this.errMsg = temp.msg
      } else if (temp.errcode === 0) {
        this.realTime = temp.realTime
        // const tableData = [null, null, null, null, null, null, null, null, null]
        /* for (let index = temp.datas.length - 1; index >= 0; index--) {
          console.log(temp.datas[index])
          if (this.companies[temp.datas[index].companyId] === undefined) {
            console.log(temp.datas[index].companyId)
            temp.datas[index].companyId = 9999
          } else {
            if (this.companies[temp.datas[index].companyId].sort !== 1000) {
              tableData[this.companies[temp.datas[index].companyId].sort - 1] = temp.datas.splice(index, 1)[0]
              continue
            }
            for (const key in temp.datas[index]) {
              if (!temp.datas[index][key]) {
                temp.datas.splice(index, 1)
                break
              }
            }
          }
        }
        for (let index = tableData.length - 1; index >= 0; index--) {
          if (tableData[index] === null) {
            tableData.splice(index, 1)
            continue
          }
          for (const key in tableData[index]) {
            if (!tableData[index][key]) {
              tableData.splice(index, 1)
              break
            }
          }
        }
        tableData.push(...temp.datas) */
        this.tableData = temp.datas
        // console.log(tableData)
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
  }
}
</script>

<style>
.bd_btm {
  display: block;
  border-bottom: 1px solid #e0e5ea;
}
.el-table .cell {
  padding: 0;
  line-height: 42px;
}
.el-table__body {
  text-align: center !important;
}
.el-table .el-table__cell {
  padding: 0;
}
</style>
